<template>
    <div class="vacation-index">
        <md-tabs ref="tabs">
            <template slot="header-item" scope="props">
                <div style="height:100%;display: flex; align-items: center;" @click.stop="jump(props)">{{ props.header.label }}</div>
            </template>
            <md-tab md-label="Cards" :md-active="tabIndex == 0">
                <tilled-card-list ref="list" v-if="tabIndex == 0"></tilled-card-list>
            </md-tab>
            <md-tab md-label="Add Card" :md-active="tabIndex == 1">
                <tilled-card-edit ref="add" :id="id" v-if="tabIndex == 1"></tilled-card-edit>
            </md-tab>
            <md-tab md-label="Edit Card" v-if="tabIndex == 2" :md-active="tabIndex == 2">
                <tilled-card-edit ref="edit" :id="id" v-if="tabIndex == 2"></tilled-card-edit>
            </md-tab>
            <md-tab v-if="showStripe" md-label="Stripe"> </md-tab>
        </md-tabs>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import TilledCardList from "@/components/Tilled/TilledCardList";
import TilledCardEdit from "@/components/Tilled/TilledCardEdit";
export default {
    props: ["id"],
    components: {
        TilledCardList,
        TilledCardEdit
    },
    data() {
        return {
            init: false,
            tabIndex: 0
        };
    },
    computed: {
        ...mapGetters({
            paymentSetting: "setting/payment"
        }),
        showStripe() {
            return this.paymentSetting.value && this.paymentSetting.value.use_stripe;
        }
    },
    watch: {
        $route: {
            // immediate: true,
            handler() {
                // this.tabIndex = this.$route.path.indexOf("cards/new") > -1 ? 1 : 0;
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "new") {
                    this.tabIndex = 1;
                } else {
                    this.tabIndex = 2;
                }

                console.info("12313", this.tabIndex, this.id);
            }
        }
    },

    methods: {
        jump(e) {
            switch (e.header.label) {
                case "Cards":
                    window.location.href = "/admin/EzTimePay/cards";
                    break;

                case "Add Card":
                    window.location.href = "/admin/EzTimePay/cards/new";
                    break;
                case "Stripe":
                    window.location.href = "/admin/stripe/cards";
                    break;
            }
        }
    },
    mounted() {
        if (!this.id) {
            this.tabIndex = 0;
        } else if (this.id == "new") {
            this.tabIndex = 1;
        } else {
            this.tabIndex = 2;
        }
    }
};
</script>

<style lang="less" scoped>
.vacation-index /deep/ .md-tab-header-container {
    height: 100%;
}
</style>
