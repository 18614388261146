<template>
    <div class="tilled-card-list">
        <list ref="tilledCardList" :fs="tilledCardFields" api="EzTimePay/cards" :editLink="editLink" title="Cards" :beforeDelete="handleBeforeDelete"></list>
    </div>
</template>
<script>
import { tilledCardFields } from "@/config";
import List from "@/components/List";
export default {
    components: {
        List
    },
    computed: {
        tilledCardFields() {
            return tilledCardFields;
        },
        editLink() {
            return `/admin/EzTimePay/cards`;
        }
    },
    methods: {
        handleBeforeDelete(item, list) {
            if (list.length <= 1) {
                const message = "There must be at least (1) valid card on file";
                this.$store.commit("setMessage", message);
                throw message;
            }
        }
    }
};
</script>
