<template>
    <div class="tilled-card-edit">
        <tilled-new-card :id="id" @confirm="confirm" @cancel="cancel"></tilled-new-card>
    </div>
</template>
<script>
import TilledNewCard from "@/components/Tilled/TilledNewCard";
export default {
    props: ["id"],
    components: {
        TilledNewCard
    },
    methods: {
        confirm({ token, customer }) {
            this.$router.replace("/admin/EzTimePay/cards");
            this.$store.dispatch("user/refreshProfile");
        },
        cancel() {
            this.$router.replace("/admin/EzTimePay/cards");
        }
    }
};
</script>
<style lang="less">
.tilled-card-edit {
    .tilled-new-card {
        max-width: 1000px;
        margin: auto;
        .md-card-header {
            display: none;
        }
    }
}
</style>
